import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import MentionsPage from './pages/Mentions.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/mentions" element={<MentionsPage />} />
    </Routes>
  );
}

export default App;
