import React from 'react';

export default function IndexSectionLogos16() {
    return (
        <React.Fragment>
            <>
                <section className="py-12 lg:py-24 bg-teal-900">
  <div className="container mx-auto px-4">
    <div className="text-center mb-16">
      <span className="font-medium text-white">Quelques entreprises qui nous ont fait confiance</span>
    </div>
    <div className="md:max-w-4xl mx-auto xl:max-w-none">
      <div className="flex flex-wrap justify-between -mx-4 lg:-mx-6 xl:-mx-10 -mb-6">
        <div className="w-1/3 md:w-1/6 px-4 lg:px-6 xl:px-10 mb-6">
          <img className="block mx-auto lg:w-full" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/Object2.png" alt />
        </div>
        <div className="w-1/3 md:w-1/6 px-4 lg:px-6 xl:px-10 mb-6">
          <img className="block mx-auto lg:w-full" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/logodecath.png" alt />
        </div>
        <div className="w-1/3 md:w-1/6 px-4 lg:px-6 xl:px-10 mb-6">
          <img className="block mx-auto lg:w-full" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/Group-3197.png" alt />
        </div>
        <div className="w-1/3 md:w-1/6 px-4 lg:px-6 xl:px-10 mb-6">
          <img className="block mx-auto lg:w-full" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/monop-logo.png" alt />
        </div>
        <div className="w-1/3 md:w-1/6 px-4 lg:px-6 xl:px-10 mb-6">
          <img className="block mx-auto lg:w-full" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/rapidflyer2.png" alt />
        </div>
        <div className="w-1/3 md:w-1/6 px-4 lg:px-6 xl:px-10 mb-6">
          <img className="block mx-auto lg:w-full" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/lcf.png" alt />
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

