import React from 'react';

export default function IndexSectionCta17() {
    return (
        <React.Fragment>
            <>
                <section>
  <div className="p-4">
    <div className="max-w-xl lg:max-w-5xl mx-auto xl:max-w-none px-5 md:px-12 xl:px-24 py-16 bg-orange-50 rounded-2xl">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
            <div className="max-w-md xl:max-w-none">
              <h1 className="font-heading text-4xl xs:text-5xl sm:text-6xl tracking-sm mb-6">Discutons de votre projet</h1>
              <p className="text-lg text-gray-700">Vous souhaitez collaborer avec notre 
                agence pour développer vos projets d'automatisation ?</p>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-4 lg:text-right">
            <a href="#" className="inline-flex py-4 px-6 items-center justify-center text-lg font-medium text-white hover:text-teal-900 border border-teal-900 hover:border-lime-500 bg-teal-900 hover:bg-lime-500 rounded-full transition duration-200">Réservez votre audit gratuit</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

