import React from 'react';

export default function IndexSectionAbout12() {
    return (
        <React.Fragment>
            <>
                <section className="py-12 lg:py-24 bg-teal-900">
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap -mx-4 mb-24">
      <div className="w-full md:w-1/2 px-4 mb-6 md:mb-0">
        <div>
          <h1 className="font-heading text-6xl text-white">Qui sommes-nous?</h1>
        </div>
      </div>
      <div className="w-full md:w-1/2 px-4">
        <div className="lg:max-w-lg md:ml-auto">
          <p className="text-xl font-medium text-gray-50 mb-10">USER PRO est le service d'automatisation de l'agence USER. Nous nous positionnons comme un partenaire digital de choix, axé sur l'optimisation de l'expérience utilisateur et le développement de solutions sur-mesure. Notre expertise s'étend de la conception stratégique à l'implémentation concrète de solutions numériques innovantes. Nous nous engageons auprès des entreprises et de leurs marques pour façonner et concrétiser leur vision digitale, en offrant un accompagnement complet et personnalisé. Notre mission est de transformer les ambitions de nos clients en réalités numériques performantes, en mettant l'accent sur l'efficacité et l'engagement des utilisateurs</p>
        </div>
      </div>
    </div>
  </div>
  <div className="flex overflow-x-hidden">
    <div className="w-1/3 lg:w-1/5 -ml-32 flex-shrink-0 px-2 md:px-4">
      <img className="block w-full h-full object-cover rounded-3xl" src="https://images.unsplash.com/photo-1587440871875-191322ee64b0?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxMHx8dXglMjByZXNlYXJjaHxlbnwwfHx8fDE3MzAyMDc0MzJ8MA&ixlib=rb-4.0.3&q=85&w=1920" alt />
    </div>
    <div className="w-1/3 lg:w-1/5 flex-shrink-0 px-2 md:px-4">
      <img className="block w-full h-full object-cover rounded-3xl" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/Group-123.png" alt />
    </div>
    <div className="w-1/3 lg:w-1/5 flex-shrink-0 px-2 md:px-4">
      <img className="block w-full h-full object-cover rounded-2xl" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/Group-456.png" alt />
    </div>
    <div className="w-1/3 lg:w-1/5 flex-shrink-0 px-2 md:px-4">
      <img className="block w-full h-full object-cover rounded-2xl" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/Group-789.png" alt />
    </div>
    <div className="w-1/3 lg:w-1/5 flex-shrink-0 px-2 md:px-4">
      <img className="block w-full h-full object-cover rounded-2xl" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/Group-31986.png" alt />
    </div>
    <div className="w-1/3 lg:w-1/5 flex-shrink-0 px-2 md:px-4">
      <img className="block w-full h-full object-cover rounded-2xl" src="https://images.unsplash.com/photo-1510751007277-36932aac9ebd?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwzMHx8ZGV2ZWxvcGVyfGVufDB8fHx8MTczMDIwNzQ2NHww&ixlib=rb-4.0.3&q=85&w=1920" alt />
    </div>
    <div className="w-1/3 lg:w-1/5 flex-shrink-0 px-2 md:px-4">
      <img className="block w-full h-full object-cover rounded-2xl" src="flow-assets/about/about-image2.png" alt />
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

