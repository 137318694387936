import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders2 from '../components/headers/IndexSectionHeaders2';
import IndexSectionAbout8 from '../components/about/IndexSectionAbout8';
import IndexSectionFeatures20 from '../components/features/IndexSectionFeatures20';
import IndexSectionCta11 from '../components/cta/IndexSectionCta11';
import IndexSectionFaq9 from '../components/faq/IndexSectionFaq9';
import IndexSectionAbout12 from '../components/about/IndexSectionAbout12';
import IndexSectionLogos16 from '../components/logos/IndexSectionLogos16';
import IndexSectionCta17 from '../components/cta/IndexSectionCta17';
import IndexSectionFaq15 from '../components/faq/IndexSectionFaq15';
import IndexSectionFooter18 from '../components/footer/IndexSectionFooter18';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders2 />
      <IndexSectionAbout8 />
      <IndexSectionFeatures20 />
      <IndexSectionCta11 />
      <IndexSectionFaq9 />
      <IndexSectionAbout12 />
      <IndexSectionLogos16 />
      <IndexSectionCta17 />
      <IndexSectionFaq15 />
      <IndexSectionFooter18 />
    </React.Fragment>
  );
}

