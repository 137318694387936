import React from 'react';

export default function IndexSectionAbout8() {
    return (
        <React.Fragment>
            <>
                <section className="p-4">
  <div className="py-16 px-4 sm:px-8 bg-orange-50 rounded-3xl">
    <div className="container mx-auto px-4">
      <div className="flex mb-4 items-center">
        <svg width={8} height={8} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx={4} cy={4} r={4} fill="#022C22" />
        </svg>
        <span className="inline-block ml-2 text-sm font-medium text-teal-900">CONSTAT</span>                        
      </div>
      <div className="border-t pt-16">
        <div className="max-w-lg mx-auto lg:max-w-none">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-2/3 px-4 mb-12 lg:mb-0">
              <div className="max-w-xl">
                <h1 className="font-heading text-5xl sm:text-6xl mb-6">Vos équipes perdent du temps avec des processus non automatisés</h1>
                <p className="text-lg text-gray-700">Les tâches répétitives et les interfaces non optimisées génèrent frustration et inefficacité, freinant ainsi la croissance de votre entreprise. Selon une étude 94% des travailleurs déclarent effectuer des tâches répétitives et chronophages dans le cadre de leur fonction.</p>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-4">
              <div>
                <div className="mb-16">
                  <span className="block text-5xl">4h/semaine</span>
                  <p className="text-lg text-gray-700">en plus passé sur des tâches répétitives</p>
                </div>
                <div className="mb-16">
                  <span className="block text-5xl">+ 30K€</span>
                  <p className="text-lg text-gray-700">de coût de main-d'oeuvre supplémentaire</p>
                </div>
                <div className="mb-16">
                  <span className="block text-5xl">45 minutes</span>
                  <p className="text-lg text-gray-700">perdues chaque jour en recherche d'informations</p>
                </div>
                <div className="mb-16">
                  <span className="block text-5xl">70%</span>
                  <p className="text-lg text-gray-700">des erreurs sont dues aux taches répétitives</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

