import React from 'react';

export default function IndexSectionFaq9() {
    return (
        <React.Fragment>
            <>
                <section className="py-12 lg:py-24">
  <div className="container mx-auto px-4">
    <div className="text-center mb-20">
      <h1 className="font-heading text-6xl mb-6">Pourquoi faire appel à un service d’automatisation ?</h1>
      <p className="text-gray-700">Nous vous permettons de gagner en efficacité, de réduire les coûts et d'optimiser les ressources en automatisant les tâches répétitives</p>
    </div>
    <div className="max-w-xl mx-auto lg:max-w-none">
      <div className="flex flex-wrap -mx-4 mb-24">
        <div className="w-full lg:w-1/2 px-4 mb-4 lg:mb-0">
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">Marketing</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour l’automatisation des campagnes par e-mail, des analyses de données, et de la gestion des réseaux sociaux.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">Service client</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour les réponses aux questions fréquentes, les suivis de tickets et les retours.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">Achats</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour la gestion des fournisseurs, les approvisionnements et les suivis de livraison.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">Logistique &amp; Supply chain</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour le suivi des stocks, la gestion des commandes et la planification des livraisons.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">Ventes</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour la gestion des leads, l'envoi de propositions et les suivis de relances.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">Ressources humaines</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour automatiser la qualification des profils, la gestion des congés et le suivis administratifs.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">Production</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour le suivi de la qualité, la gestion des stocks de matières premières, et la maintenance prédictive des machines.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
          <button className="flex w-full py-4 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
            <div className="pr-5">
              <h5 className="text-lg font-medium">IT &amp; Support Technique</h5>
              <div className="overflow-hidden h-0 duration-500">
                <p className="text-gray-700 mt-4">pour les dépannages courants, la surveillance des systèmes et la gestion des accès.</p>
              </div>
            </div>
            <span className="flex-shrink-0">
              <div>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
              </div>
              <div className="hidden">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>  
              </div>    
            </span>                       
          </button>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

