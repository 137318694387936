import React from 'react';

export default function IndexSectionFooter18() {
    return (
        <React.Fragment>
            <>
                <section className="bg-teal-900">
  <div className="container mx-auto px-4">
    <div className="max-w-xl mx-auto xl:max-w-none">
      <div className="relative flex flex-wrap py-12 items-center justify-between">
        <a href="#" className="inline-block">
          <img className="h-8" src="https://static.shuffle.dev/uploads/files/79/792079ab876d6cf20c1b77b0476bc476fdfac183/Group-3212.png" alt />
        </a>
        <ul className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden xl:flex">
          <li className="mr-16"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">Constat</a></li>
          <li className="mr-16"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">Méthode</a></li>
          <li className="mr-16"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">Cas d'usage</a></li>
          <li className="mr-16"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">FAQ</a></li>
          <li><a className="inline-block text-white hover:text-lime-500 font-medium" href="/mentions">Mentions légales</a></li>
        </ul>
        <div className="flex items-center justify-end">
          <a href="#" className="inline-block mr-4 text-white opacity-80 hover:opacity-100">
          </a>
          <a href="#" className="inline-block mr-4 text-white opacity-80 hover:opacity-100">
          </a>
          <a href="#" className="inline-block text-white opacity-80 hover:opacity-100">
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="currentColor" />
            </svg>                                                  
          </a>
        </div>
        <div className="w-full xl:hidden mt-8">
          <ul className="flex flex-wrap -mb-4 xs:justify-between">
            <li className="mb-4 mr-8"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">Solutions</a></li>
            <li className="mb-4 mr-8"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">How it works</a></li>
            <li className="mb-4 mr-8"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">Pricing</a></li>
            <li className="mb-4 mr-8"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">About</a></li>
            <li className="mb-4"><a className="inline-block text-white hover:text-lime-500 font-medium" href="#">Blog</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

