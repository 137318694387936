import React from 'react';

export default function IndexSectionFaq15() {
    return (
        <React.Fragment>
            <>
                <section className="py-12 lg:py-24">
  <div className="container mx-auto px-4">
    <div className="flex mb-4 items-center">
      <svg width={8} height={8} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={4} cy={4} r={4} fill="#BEF264" />
      </svg>                      
      <span className="inline-block ml-2 text-sm font-medium text-teal-900">FAQ</span>                        
    </div>
    <div className="border-t pt-16">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
          <div className="flex flex-col h-full max-w-sm sm:max-w-lg">
            <h1 className="font-heading text-4xl sm:text-6xl mb-16">En savoir plus sur notre service</h1>
            <div className="mt-auto">
              <div className="mb-8">
              </div>
              <div>
                <h5 className="text-xl font-medium mb-4">Vous avez encore des questions ?</h5>
                <p className="text-gray-700">
                  <span>N'hésitez pas à nous</span>
                  <a href="#" className="inline-block text-black font-medium underline">contacter</a>
                  <span>page or call our customer support hotline at</span>
                  <span>. Notre équipe se tient à votre disposition pour répondre à toutes vos intérrogations. </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div>
            <button className="flex w-full py-6 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
              <div className="pr-5">
                <h5 className="text-lg font-medium"> Qu'est-ce qu'une agence d'automatisation ?</h5>
                <div className="overflow-hidden h-0 duration-500">
                  <p className="text-gray-700 mt-4">Une agence spécialisée en automatisation crée des solutions pour automatiser les tâches répétitives et améliorer l'efficacité opérationnelle dans différents domaines de votre entreprise.</p>
                </div>
              </div>
              <span className="flex-shrink-0">
                <div>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>     
                </div>
                <div className="hidden">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>  
                </div>
              </span>                       
            </button>
            <button className="flex w-full py-6 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
              <div className="pr-5">
                <h5 className="text-lg font-medium">En quoi l’automatisation peut-elle être un atout pour mon entreprise ?</h5>
                <div className="overflow-hidden h-0 duration-500">
                  <p className="text-gray-700 mt-4">Elle permet d'augmenter la productivité, de diminuer les coûts, d'améliorer la précision des tâches et de libérer les équipes pour des missions plus stratégiques.</p>
                </div>
              </div>
              <span className="flex-shrink-0">
                <div>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>     
                </div>
                <div className="hidden">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>  
                </div>
              </span>                       
            </button>
            <button className="flex w-full py-6 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
              <div className="pr-5">
                <h5 className="text-lg font-medium">Faut-il transformer notre infrastructure actuelle pour adopter l’automatisation ?</h5>
                <div className="overflow-hidden h-0 duration-500">
                  <p className="text-gray-700 mt-4">Non, nos solutions s’intègrent aisément à votre infrastructure en place, ce qui limite les interruptions et les coûts supplémentaires.</p>
                </div>
              </div>
              <span className="flex-shrink-0">
                <div>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>     
                </div>
                <div className="hidden">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>  
                </div>
              </span>                       
            </button>
            <button className="flex w-full py-6 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
              <div className="pr-5">
                <h5 className="text-lg font-medium">Quel est le délai pour mettre en œuvre l’automatisation dans mon entreprise ?</h5>
                <div className="overflow-hidden h-0 duration-500">
                  <p className="text-gray-700 mt-4">Le temps nécessaire dépend de la complexité du projet, mais en général, nous mettons tout en œuvre pour l’implémenter en quelques semaines.</p>
                </div>
              </div>
              <span className="flex-shrink-0">
                <div>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>     
                </div>
                <div className="hidden">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>  
                </div>
              </span>                       
            </button>
            <button className="flex w-full py-6 px-8 mb-4 items-start justify-between text-left shadow-md rounded-2xl">
              <div className="pr-5">
                <h5 className="text-lg font-medium">L’automatisation est-elle fiable en matière de sécurité ?</h5>
                <div className="overflow-hidden h-0 duration-500">
                  <p className="text-gray-700 mt-4">Absolument, la sécurité est notre priorité principale. Nous intégrons les technologies de pointe pour garantir la protection de vos données et de vos processus.</p>
                </div>
              </div>
              <span className="flex-shrink-0">
                <div>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5.69995V18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>     
                </div>
                <div className="hidden">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.69995 12H18.3" stroke="#1D1F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>  
                </div>
              </span>                       
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

